import React, { Fragment, useRef } from "react"
import Layout from "../components/layout/layout"

import Hero from "../components/shared/hero"

// Images
import { getImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import { convertToBgImage } from "gbimage-bridge"
const Work = () => {
  const { backgroundImg } = useStaticQuery(
    graphql`
      query {
        backgroundImg: file(relativePath: { eq: "hero-banner-light.webp" }) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, quality: 100)
          }
        }
      }
    `
  )
  const img = getImage(backgroundImg)
  const bgImg = convertToBgImage(img)

  const formRef = useRef(null)

  return (
    <Fragment>
      <Layout>
        {/* Hero */}
        <Hero bgImg={bgImg} header="Let’s Talk" tagline="JUST DROP ME A LINE" />
        <button className="bg-primary py-6 px-8 text-2xl uppercase text-white  hover:bg-white hover:text-primary transition duration-300 w-full">
          Contact Now
        </button>
      </Layout>
    </Fragment>
  )
}

export default Work
